import React from 'react'
import tw from 'twin.macro'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import { FormHeader, FormFooter } from '../components/FormControls'
import Button from '../components/Button'

// This will prevent the 404 from flashing on private routes
const browser = typeof window !== 'undefined' && window

const NotFoundPage = () =>
  browser && (
    <Layout hideSteps>
      <SEO title="404: Not found" />
      <Container>
        <FormHeader>
          <h1 tw="text-2xl md:text-4xl font-bold text-purple mb-4">Oh no!</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
        </FormHeader>
        <FormFooter tw="sm:justify-center">
          <Button to="/" isPrimary>
            Go to the homepage
          </Button>
        </FormFooter>
      </Container>
    </Layout>
  )

export default NotFoundPage
